import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import mainlogo from '../../assets/logo.png';
import chatbox from '../../assets/chatbox-icon.png';
import adminimg from '../../assets/admin-img.png';




const WithAuthHeader = (props) => {
	return <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark"  className="headermain">
		{/* <Container> */}
		<Navbar.Brand href="/">
		<img src={mainlogo} />
		</Navbar.Brand>
		<Navbar.Toggle aria-controls="responsive-navbar-nav" />
		<Navbar.Collapse id="responsive-navbar-nav">
			<Nav className="m-auto">
				<Nav.Link href="#dashboard" className="active">Dashboard</Nav.Link>
				<Nav.Link href="#progress">Progress</Nav.Link>
				<Nav.Link href="#team">Team</Nav.Link>
			</Nav>
			<Nav className="righttopnav">
				<Nav.Link href="#chat"><img src={chatbox} /></Nav.Link>
				<div className="adminlogimg"><img src={adminimg} /></div>
				<NavDropdown title="Tania Sharma" id="collasible-nav-dropdown">
					<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
					<NavDropdown.Item href="#action/3.2">
						Another action
					</NavDropdown.Item>
					<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
					<NavDropdown.Divider />
					<NavDropdown.Item href="#action/3.4">
						Separated link
					</NavDropdown.Item>
				</NavDropdown>
			</Nav>
		</Navbar.Collapse>
		{/* </Container> */}
	</Navbar>
}
export default WithAuthHeader