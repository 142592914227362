import WithOutAuthHeader from "../components/header/WithOutAuthHeader"
import Table from 'react-bootstrap/Table';
import { Card, Container } from "react-bootstrap";
import WithAuthHeader from "../components/header/WithAuthHeader";
import icon1 from '../assets/icon-1.png';
import icon2 from '../assets/icon-2.png';
import icon3 from '../assets/icon-3.png';
import handicon from '../assets/handicon.png';
import handopn from '../assets/handopn.png';
import closebtn from '../assets/close-btn.png';
import adminimg from '../assets/admin-img.png';
import arrowdown from '../assets/arrow-down.png';


const Dashboard = (props) => {
	return (
		<>
			<WithAuthHeader />

			{/* top image */}
			<div className="dashboardbanner">
			     <Container><h1 className="t-white">Welcome back, Tania! <img src={handicon} /></h1></Container>
			</div>
			<div className="dashboard-body bg-white">
			<Container>
				{/* Top cards */}
				<Card className="mb-3">
					<Card.Body>
						<div className="text-start mb-3">
							<h3><b> Stay on top of your wellness!</b></h3>
						</div>
						<div className="row designrow">
							<div className="col-lg-4 yellow">
								<div className="designcol" style={{backgroundColor: '#FFFFF3', borderWidth: 1, borderRadius: 10, borderColor: '#EFEFC8', borderStyle: 'solid',}} >
									<div className="designcontent">
										<p>Article</p>
										<h5><b>8 Ways Managers Can Support Employees</b></h5>
									</div>
									<div className="designimage"><img src={icon1} /></div>
								</div>
							</div>
							<div className="col-lg-4 green">
								<div className="designcol" style={{backgroundColor: '#E9FAF5', borderWidth: 1, borderRadius: 10, borderColor: '#C1E7DC', borderStyle: 'solid',}}>
									<div className="designcontent">
										<p>Article</p>
										<h5><b>Why should mental health be your business strategy? </b></h5>
									</div>
									<div className="designimage"><img src={icon2} /></div>
								</div>
							</div>
							<div className="col-lg-4 purple">
								<div className="designcol" style={{backgroundColor: '#E9ECFA', borderWidth: 1, borderRadius: 10, borderColor: '#BFC7ED', borderStyle: 'solid',}}>
									<div className="designcontent">
										<p>Article</p>
										<h5><b>Why should mental health be your business strategy? </b></h5>
									</div>
									<div className="designimage"><img src={icon3} /></div>
								</div>
							</div>
						</div>
					</Card.Body>
				</Card>

				{/* second card */}
				<Card>
					<Card.Body>
						<div className="protip-note">
						<div className="protip-note-left"><img src={handopn}/></div>
						<div className="protip-note-right">
							<h6><b>Pro-tip to help you  prepare for your next appointment! </b></h6>
							<p>Write down business goals you hope to achieve in the next 3-6-12 months!</p>
						</div>
							<div className="protip-close"><a href="#"><img src={closebtn}/></a></div>
						</div>
						<Card.Title><div className="text-start mb-3"><h3><b>Upcoming Appointments</b></h3></div></Card.Title>
						<Table responsive bordered >
							<thead>
								<tr>
									<th>Date & time <img src={arrowdown} /></th>
									<th>Attendee(s) <img src={arrowdown} /></th>
									<th>Session Type <img src={arrowdown} /></th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td><h5>Sept 22, 2022</h5> <p>11:00 AM</p></td>
									<td><span className="therapistimg"><img src={adminimg} /></span>Jordan Bergen </td>
									<td>Therapist Session</td>
									<td><a href="#" className="btn greenborder">Join Meeting</a></td>
								</tr>
								<tr>
								<td><h5>Sept 22, 2022</h5> <p>11:00 AM</p></td>
									<td><span className="therapistimg"><img src={adminimg} /><img src={adminimg} /><img src={adminimg} /></span>Peer Group</td>
									<td>Therapist Session</td>
									<td><a href="#" className="btn greenborder">Join Meeting</a></td>
								</tr>
								<tr>
								<td><h5>Sept 22, 2022</h5> <p>11:00 AM</p></td>
									<td><span className="therapistimg"><img src={adminimg} /><img src={adminimg} /><img src={adminimg} /></span>Peer Group</td>
									<td>Therapist Session</td>
									<td><a href="#" className="btn greenborder">Join Meeting</a></td>
								</tr>
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</Container>
		</div>
		</>
	)
}
export default Dashboard