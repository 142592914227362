import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import backicon from '../assets/back.png';

const GenderForm = (props) => {
	return (
		<>
		<div className="formwrapper">
			<h2 className='t-white mb-5'><b>What gender do you identify with?</b></h2>
			<Form  className="formwidth">
				<Form.Group className="mb-4" controlId="formBasicEmail">
					<Form.Select>
						<option>Gender</option>
						<option>Male</option>
						<option>Female</option>
						<option>Transgender</option>
						<option>Non-Binary/Gender-nonconfirming</option>
						<option>Prefer to self describe</option>
					</Form.Select>
					<Form.Text className="text-muted">
						<span className='text-start'>We'll never share your email with anyone else.</span>
					</Form.Text>
				</Form.Group>
				<Button variant="primary" type="submit">
					Continue
				</Button>
				<Button variant="primary" type="submit" className='backbtn'>
				<img src={backicon} /> Back
				</Button>
			</Form>
		</div>
		</>
	)
}
export default GenderForm