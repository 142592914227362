import GenderForm from "../components/GenderForm"
import WithOutAuthHeader from "../components/header/WithOutAuthHeader"
import StepBar from "../components/StepBar"

const Basic = (props) => {
	return (
		<>
		<div className="loginsteppage">
			<WithOutAuthHeader />
			<StepBar />
			<GenderForm />
		</div>
		</>
	)
}
export default Basic