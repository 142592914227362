import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import mainlogo from '../../assets/logo.png';


const WithOutAuthHeader = (props) => {
	return <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark"  className="headerone">
		{/* <Container> */}
		<Navbar.Brand href="#home"><img src={mainlogo} /></Navbar.Brand>
		<Navbar.Toggle aria-controls="responsive-navbar-nav" />

		{/* </Container> */}
	</Navbar>
}
export default WithOutAuthHeader