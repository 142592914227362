import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
const StepBar = (props) => {
	return <div className='infowidth'>
		<h6 className='t-grey'>Getting to know you</h6>
		{/* Steps */}
		<div className='stepsrow row justify-content-between'>
		    <div className='stepscol step-one bg-grad'></div>
			<div className='stepscol step-two bg-grad'></div>
			<div className='stepscol step-three bg-grad'></div>
			<div className='stepscol step-four'></div>
			<div className='stepscol step-five'></div>
			<div className='stepscol step-six'></div>
		</div>
		
	</div>
}
export default StepBar